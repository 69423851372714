exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-templates-about-page-template-tsx": () => import("./../../../src/templates/AboutPageTemplate.tsx" /* webpackChunkName: "component---src-templates-about-page-template-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-sub-category-page-tsx": () => import("./../../../src/templates/SubCategoryPage.tsx" /* webpackChunkName: "component---src-templates-sub-category-page-tsx" */),
  "component---src-templates-tag-page-tsx": () => import("./../../../src/templates/TagPage.tsx" /* webpackChunkName: "component---src-templates-tag-page-tsx" */)
}

